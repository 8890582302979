import store from "@/state/store";
import axios from 'axios';
import qs from "qs";


export default [
    {
        path: "/tab/:chapters*",
        name: "deeplink",
        component: () => import("../views/front/index.vue"),
        meta: {
            title: "LocalMan",
            currentDynamicUrl:true,
            encodeDynamicURI:true,
            //authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                next();
            },
        },
    },
    {
        path: "/flow/:chapters*",
        name: "deeplink_flow",
        component: () => import("../views/front/index.vue"),
        meta: {
            title: "LocalMan",
            currentDynamicUrl:true,
            encodeDynamicURI:true,
            //authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                next();
            },
        },
    },
    {
        path: "/",
        name: "front",
        component: () => import("../views/front/index.vue"),
        meta: {
            title: "LocalMan",
            //authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                next();
            },
        },
    },
    {
        path: "/download",
        name: "download",
        component: () => import("../views/front/index.vue"),
        meta: {
            title: "LocalMan",
            //authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                next();
            },
        },
    },

    {
        path: "/line/callback",
        name: "line_callback",
        //component: () => import("../views/front/login.vue"),
        meta: {
            title: "callback",
            beforeResolve(routeTo, routeFrom, next) {
                const urlParams = new URLSearchParams(window.location.search);
                //const state = urlParams.get('state');
                const code = urlParams.get('code');
                console.log(code);

                //get access token
                const URL = 'https://api.line.me/oauth2/v2.1/token';
                let redirect_uri = window.location.protocol + '//'+ window.location.host + '/line/callback';
                const getTokenUrl = `${URL}`;
                const getTokenBody = qs.stringify({
                    grant_type: 'authorization_code',
                    code : code,
                    redirect_uri: redirect_uri,
                    client_id: process.env.VUE_APP_LINE_ID,
                    client_secret: process.env.VUE_APP_Line_SECRET,
                });

                axios.post(getTokenUrl, getTokenBody ).then((res) => {
                    let access_token = res.data.access_token;
                    //backend login

                    axios.post(`${process.env.VUE_APP_API}api/auth/line/login`, { code : access_token }).then((res) => {
                        console.log(res);
                        store.dispatch('apiauth/socialLogin' , {user:res.data.data.user , token: res.data.data.token} );
                        next({name: "qa"});
                    });
                });

                //next();
                // If the user is already logged in
                /*
                if (store.getters["apiauth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({name: "qa"});
                } else {
                    // Continue to the login page
                    next();
                }

                 */
            },
        },
    },
    {
        path: "/logout",
        name: "logout",
        //component: () => import("../views/auth/logout/basic"),
        meta: {
            title: "Logout",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                localStorage.clear();
                sessionStorage.clear();
                store.dispatch('apiauth/logOut');
                next({name: "login"});
                //next();
                //next({name: "login"});
            },
        },
    },
    {
        path: "/admin/login",
        name: "admin_login",
        component: () => import("../views/admin/login.vue"),
        meta: {
            title: "Login",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["apiauth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({name: "default"});
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },

    {
        path: "/admin/logout",
        name: "admin_logout",
        meta: {
            title: "Logout",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                localStorage.clear();
                sessionStorage.clear();
                store.dispatch('apiauth/logOut');
                //next();
                next({name: "admin_login"});
            },
        },
        //component: () => import("../views/auth/logout/basic")
    },
    {
        path: "/admin/default",
        name: "default",
        meta: {
            title: "Dashboard",
            authRequired: true,
        },
        component: () => import("../views/admin/default.vue"),
    },
    {
        path: "/admin/group_buy",
        name: "group_buy",
        meta: {
            title: "團購管理",
            authRequired: true,
        },
        component: () => import("../views/admin/group_buy/index.vue"),
    },
    {
        path: "/admin/group_buy/:id/times",
        name: "group_buy_times",
        meta: {
            title: "團購時段管理",
            authRequired: true,
        },
        component: () => import("../views/admin/group_buy_time/index.vue"),
    },
    {
        path: "/admin/group_buy_times/:id/goods",
        name: "group_buy_times_goods",
        meta: {
            title: "團購時段商品管理",
            authRequired: true,
        },
        component: () => import("../views/admin/group_buy_time_goods/index.vue"),
    },
    {
        path: "/admin/group_buy_arrival",
        name: "group_buy_arrival",
        meta: {
            title: "團購到貨管理",
            authRequired: true,
        },
        component: () => import("../views/admin/group_buy_arrival/index.vue"),
    },
    {
        path: "/admin/group_buy_total",
        name: "group_buy_total",
        meta: {
            title: "團購整單管理",
            authRequired: true,
        },
        component: () => import("../views/admin/group_buy_total/index.vue"),
    },
    {
        path: "/admin/group_buy_total/:id/orders",
        name: "group_buy_total_orders",
        meta: {
            title: "團購整單管理",
            authRequired: true,
        },
        component: () => import("../views/admin/group_buy_total/orders.vue"),
    },
    {
        path: "/admin/group_buy_times/:id/orders",
        name: "group_buy_times_orders",
        meta: {
            title: "團購整單列表",
            authRequired: true,
        },
        component: () => import("../views/admin/group_buy_time_orders/index.vue"),
    },
    {
        path: "/admin/group_buy_pick_up",
        name: "group_buy_pick_up",
        meta: {
            title: "團購領貨列表",
            authRequired: true,
        },
        component: () => import("../views/admin/group_buy_pick_up/index.vue"),
    },
    {
        path: "/admin/group_buy_times/:id/orders_pick_up",
        name: "group_buy_times_orders_pickup",
        meta: {
            title: "團購領貨",
            authRequired: true,
            mode:'pick_up'
        },
        component: () => import("../views/admin/group_buy_time_orders/index.vue"),
    },
    {
        path: "/admin/group_buy_total/:id/pickup",
        name: "group_buy_total_order",
        meta: {
            title: "團購整單管理",
            authRequired: true,
            mode:'pick_up'
        },
        component: () => import("../views/admin/group_buy_total/orders.vue"),
    },
    {
        path: "/admin/orders",
        name: "orders",
        meta: {
            title: "訂單管理",
            authRequired: true,
        },
        component: () => import("../views/admin/orders/index.vue"),
    },
    {
        path: "/admin/goods",
        name: "goods",
        meta: {
            title: "商品管理",
            authRequired: true,
        },
        component: () => import("../views/admin/goods/index.vue"),
    },
    {
        path: "/admin/orders_new",
        name: "admin_orders_new",
        meta: {
            title: "團購訂單管理",
            authRequired: true,
        },
        component: () => import("../views/admin/orders_new/index.vue"),
    },
    {
        path: "/admin/group_buy/:id/pickup",
        name: "group_buy_pickup",
        meta: {
            title: "取貨",
            authRequired: true,
            mode:'pick_up'
        },
        component: () => import("../views/admin/group_buy_total/pickup.vue"),
    },
    {
        path: "/admin/default_locations",
        name: "default_locations",
        meta: {
            title: "預設地點管理",
            authRequired: true,
        },
        component: () => import("../views/admin/default_locations/index.vue"),
    },
    {
        path: "/admin/goods_groups",
        name: "goods_groups",
        meta: {
            title: "商品群組管理",
            authRequired: true,
        },
        component: () => import("../views/admin/goods_groups/index.vue"),
    },




    {
        path: "/admin/result",
        name: "admin_result",
        meta: {
            title: "問答結果",
            authRequired: true,
        },
        component: () => import("../views/admin/result.vue"),
    },
    {
        path: "/admin/users",
        name: "admin_users",
        meta: {
            title: "使用者管理",
            authRequired: true,
        },
        component: () => import("../views/admin/users/index.vue"),
    },
    {
        path: "/admin/service_types",
        name: "admin_service_types",
        meta: {
            title: "服務項目管理",
            authRequired: true,
        },
        component: () => import("../views/admin/service_types/index.vue"),
    },
    {
        path: "/admin/service_places",
        name: "admin_service_places",
        meta: {
            title: "建築類型",
            authRequired: true,
        },
        component: () => import("../views/admin/service_places/index.vue"),
    },
    {
        path: "/admin/service_types_law",
        name: "admin_service_types_law",
        meta: {
            title: "法規",
            authRequired: true,
        },
        component: () => import("../views/admin/service_types_law/index.vue"),
    },
    {
        path: "/admin/service_types_doc",
        name: "admin_service_types_doc",
        meta: {
            title: "應備項目",
            authRequired: true,
        },
        component: () => import("../views/admin/service_types_doc/index.vue"),
    },
    {
        path: "/admin/service_types/:service_type_id/option_type/:option_type",
        name: "admin_service_type_option",
        meta: {
            title: "服務項目選項管理",
            authRequired: true,
        },
        component: () => import("../views/admin/service_types/option.vue"),
    },
    {
        path: "/admin/regular_service",
        name: "admin_regular_service",
        meta: {
            title: "定期檢修",
            authRequired: true,
        },
        component: () => import("../views/admin/regular_service/index.vue"),
    },
    {
        path: "/admin/regular_service_type",
        name: "admin_regular_service_type",
        meta: {
            title: "定期檢修類別",
            authRequired: true,
        },
        component: () => import("../views/admin/regular_service_type/index.vue"),
    },
    {
        path: "/admin/customers",
        name: "admin_customers",
        meta: {
            title: "顧客管理",
            authRequired: true,
        },
        component: () => import("../views/admin/customers/index.vue"),
    },
    {
        path: "/admin/news_group",
        name: "admin_news_group",
        meta: {
            title: "群組管理",
            authRequired: true,
        },
        component: () => import("../views/admin/news_group/index.vue"),
    },
    {
        path: "/admin/news",
        name: "admin_news",
        meta: {
            title: "發送管理",
            authRequired: true,
        },
        component: () => import("../views/admin/news/index.vue"),
    },
];
